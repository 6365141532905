import MobileNavItem from "./MobileNavItem";
import type { FunctionComponent } from "react";
import { Menu, MenuAdditionalLink, NavigationType } from "../types";
import { css } from "@emotion/react";

interface Props {
  data: NavigationType;
  onClick: (item: Menu | MenuAdditionalLink) => void;
}

const MobileNavItemsCss = css`
  margin: 0;
  padding: 0;
  display: grid;
`;

const MobileNavItems: FunctionComponent<Props> = ({ data, onClick }) => (
  <ul css={MobileNavItemsCss}>
    {data.menu.map((item) => (
      <MobileNavItem
        key={item.label}
        label={item.label}
        onClick={() => onClick(item)}
      />
    ))}
    {data.additionalLinks.map((link) => (
      <MobileNavItem
        key={link.label}
        label={link.label}
        onClick={() => onClick(link)}
      />
    ))}
  </ul>
);

export default MobileNavItems;
