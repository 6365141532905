import {
  borderRadiusMedium,
  colorSteelDarkest,
  colorSteelLighter,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { forwardRef, type ForwardRefRenderFunction } from "react";
import Anchor from "../../Anchor";
import { Mode } from "../types";

interface Props {
  children: ReactNode;
  className?: string;
  id?: string;
  locale?: string | false;
  mode?: Mode;
  target?: "_blank" | "_self";
  url: string;
}

const getLinkStyles = (mode: Mode) => {
  const textColor = mode === "dark" ? "white" : colorSteelDarkest;
  const backgroundColor =
    mode === "dark" ? "rgba(225, 239, 252, 0.25)" : "rgba(68, 89, 121, 0.1)";

  return css`
    background: ${backgroundColor};
    border-radius: ${borderRadiusMedium};
    color: ${textColor};
    display: block;
    padding: 4px 10px 6px;

    &:hover {
      background: ${mode === "dark"
        ? "rgba(250, 250, 250, 0.3)"
        : colorSteelLighter};
    }
  `;
};

const listItemStyles = css`
  display: inline-block;
`;

const AdditionalLink: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
  { children, className, id, locale, mode = "light", target = "_self", url },
  ref,
) => {
  return (
    <li className={className} css={listItemStyles} id={id}>
      <Anchor
        css={getLinkStyles(mode)}
        hoverColor={mode === "dark" ? "white" : "base"}
        href={url}
        locale={locale}
        ref={ref}
        target={target}
      >
        {children}
      </Anchor>
    </li>
  );
};

export default forwardRef(AdditionalLink);
