import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, type ForwardRefRenderFunction } from "react";

import MenuListItem from "./MenuListItem";
import { CompanyMenuItemType } from "../types";
import { MenuKeydownHandlers } from "./useMenuKeydownHandlers";
import Flex from "../../Flex";
import { useMenuNavigation } from "./useMenuNavigation";

interface CompanyMenuProps extends MenuKeydownHandlers {
  handleClose: () => void;
  menuItems: CompanyMenuItemType[];
}

const containerStyles = css`
  background-color: ${colorWhite};
  border-radius: 16px;
  width: 264px;
  border: 1px solid ${colorSteelLighter};
  overflow: hidden;
  padding: 1rem 0;
`;

const CompanyMenu: ForwardRefRenderFunction<
  HTMLDivElement,
  CompanyMenuProps
> = (
  { handleClose, menuItems, onEscapeKeyDown, onShiftTabKeyDown, onTabKeyDown },
  ref,
) => {
  const {
    submenuRefs,
    activeMenuItemId,
    activeMenuItemIndex,
    handleKeyDown,
    handleMenuItemHover,
    handleMenuItemLeave,
  } = useMenuNavigation({
    menuItems,
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });
  return (
    <div
      css={containerStyles}
      onKeyDown={(event) => handleKeyDown(event, activeMenuItemIndex)}
      ref={ref}
    >
      <Flex
        css={css`
          height: 100%;
        `}
        flexDirection="column"
        role="list"
      >
        {menuItems.map((item, index) => (
          <MenuListItem
            key={item.id}
            active={activeMenuItemId === item.id}
            compact
            handleClose={handleClose}
            link={item.link}
            title={item.title}
            onHover={() => handleMenuItemHover(item.id)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onMouseLeave={() => {
              handleMenuItemLeave();
              submenuRefs.current[index]?.blur();
            }}
            ref={(el) => el && (submenuRefs.current[index] = el)}
          />
        ))}
      </Flex>
    </div>
  );
};

export default forwardRef(CompanyMenu);
