import Anchor from "../../Anchor";
import Text, { colors } from "../../Text";
import { LinkMenuType } from "../types";

interface NavigationLinkProps {
  navItem: LinkMenuType;
  index: number;
  activeIndex: number;
  textColor: keyof typeof colors;
  getNavItemStyle: (isActive: boolean) => any;
  menuRefs: React.MutableRefObject<(HTMLElement | null)[]>;
}

const NavigationLink = ({
  navItem,
  index,
  activeIndex,
  textColor,
  getNavItemStyle,
  menuRefs,
}: NavigationLinkProps) => (
  <li key={navItem.url}>
    <Anchor
      css={getNavItemStyle(activeIndex === index)}
      href={navItem.url}
      ref={(el) => {
        if (el) {
          menuRefs.current[index] = el;
        }
      }}
    >
      <Text as="span" color={textColor} size="small" weight="semibold">
        {navItem.label}
      </Text>
    </Anchor>
  </li>
);

export default NavigationLink;
