import Icon from "../../Icon";
import Text from "../../Text";
import { FunctionComponent, MouseEventHandler } from "react";
import { colorSteelDarkest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Flex from "../../Flex";
import FlexItem from "../../Flex/FlexItem";

const MobileNavItemCss = css`
  list-style: none;
  width: 100%;
`;

const ButtonCss = css`
  align-items: center;
  background: none;
  border: none;
  color: ${colorSteelDarkest};
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  text-align: left;
  width: 100%;
`;

const MobileNavItem: FunctionComponent<{
  description?: string;
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ description, label, onClick }) => (
  <li css={MobileNavItemCss}>
    <button css={ButtonCss} onClick={onClick}>
      <Flex flexDirection="column" margin={"0 1rem 0 0"}>
        <Text
          as="span"
          color="steelDarker"
          css={css`
            margin-right: 0.5rem;
          `}
          size="medium"
          weight="semibold"
        >
          {label}
        </Text>
        {description ? (
          <Text as="span" color="steelDark" size="small" weight="regular">
            {description}
          </Text>
        ) : null}
      </Flex>
      <FlexItem flex="0 0 auto">
        <Icon color="base" size="10px" source="nav-right" yPos="2px" />
      </FlexItem>
    </button>
  </li>
);
export default MobileNavItem;
