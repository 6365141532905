import { type FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";
import { MenuItem } from "../types";
import { isLinksPanel, isProductMenuItem, isResourceMenuItem } from "../utils";
import Flex from "../../Flex";
import MobileNavItem from "./MobileNavItem";
import Icon from "../../Icon";

type Direction = "column" | "row";

interface Props {
  direction: Direction;
  items: MenuItem[];
  onNavigate: () => void;
  onSectionItemClick: (item: MenuItem) => void;
}

const NavSections: FunctionComponent<Props> = ({
  direction,
  items,
  onNavigate,
  onSectionItemClick,
}) => {
  return (
    <Flex flexDirection={direction} gap="20px">
      {items.map((item) => {
        const hasDescription =
          isProductMenuItem(item) || isResourceMenuItem(item);
        const hasLinks =
          isProductMenuItem(item) &&
          isLinksPanel(item.panel) &&
          item.panel.links.length > 0;

        const hasIcon = isProductMenuItem(item) && !!item.stickAtBottom;

        return hasLinks ? (
          <MobileNavItem
            description={item.description}
            label={item.title}
            onClick={() => onSectionItemClick(item)}
          />
        ) : (
          <Flex flexDirection="column" key={item.id} padding="0.75rem 0">
            <Anchor
              color="inherit"
              href={item.link ?? ""}
              onClick={onNavigate}
              target="_self"
              underlineOnHover
            >
              <Flex alignItems="center" gap="1rem">
                <Text
                  as="span"
                  color="blueMedium"
                  size="medium"
                  weight="semibold"
                >
                  {item.title}
                </Text>
                {hasIcon && (
                  <Icon color="blue" size="16px" source="arrow-right" />
                )}
              </Flex>
            </Anchor>

            {hasDescription && item.description && (
              <Text as="span" color="steelDarker" size="small" weight="regular">
                {item.description}
              </Text>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default NavSections;
