import {
  mediaDesktop,
  mediaDesktopBig,
  mediaPhoneOnly,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { kebabCase } from "lodash";

export const phone = `@media (max-width: ${mediaTabletLandscape})`;

export type WithResponsive<T> =
  | T
  | {
      base?: T;
      desktop?: T;
      desktopBig?: T;
      phoneOnly?: T;
      tabletLandscape?: T;
      tabletPortrait?: T;
    };

interface ResponsiveStyles<T> {
  [k: string]: WithResponsive<T> | undefined;
}

export function generateResponsiveCSS<T>(styles: ResponsiveStyles<T>) {
  const reducedStyles = Object.entries(styles).reduce<
    Record<string, Record<string, T>>
  >((accumulator, [key, responsiveStyles]) => {
    if (responsiveStyles) {
      if (typeof responsiveStyles === "string") {
        if (!accumulator["base"]) {
          accumulator["base"] = {};
        }
        accumulator["base"][key] = responsiveStyles;
      } else {
        for (const [breakpoint, value] of Object.entries(responsiveStyles)) {
          if (!accumulator[breakpoint]) {
            accumulator[breakpoint] = {};
          }
          accumulator[breakpoint][key] = value;
        }
      }
    }
    return accumulator;
  }, {});

  function convertStylesToString<T>(styles: Record<string, T>) {
    if (styles) {
      return `${Object.entries(styles)
        .map<string>(([style, value]) => {
          return `${kebabCase(style)}: ${value}`;
        })
        .join(";")};`;
    }
    return "";
  }

  function toMediaQueryString(breakpoint: string, cssString: string) {
    const breakpointValues = new Map<string, string>(
      Object.entries({
        desktop: mediaDesktop,
        desktopBig: mediaDesktopBig,
        phoneOnly: mediaPhoneOnly,
        tabletLandscape: mediaTabletLandscape,
        tabletPortrait: mediaTabletPortrait,
      }),
    );
    const target = breakpoint === "phoneOnly" ? "max-width" : "min-width";
    return `@media (${target}: ${breakpointValues.get(
      breakpoint,
    )}) { ${cssString} }`;
  }

  return `${Object.entries(reducedStyles)
    .map(([key, value]) => {
      if (key === "base") {
        return convertStylesToString(value);
      }

      return toMediaQueryString(key, convertStylesToString(value));
    })
    .join("")}`;
}
