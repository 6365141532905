import {
  colorBlueDark,
  colorBlueMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, useCallback, type ForwardRefRenderFunction } from "react";
import Anchor from "../../Anchor";
import Icon from "../../Icon";
import { IconSource } from "../../Icon/types";
import Text from "../../Text";
import Flex from "../../Flex";
import FlexItem from "../../Flex/FlexItem";

const linkStyles = css`
  background-color: ${colorWhite};
  color: ${colorBlueMedium};
  cursor: pointer;
  position: relative;
  width: 100%;
  &:hover,
  &:focus {
    color: ${colorBlueDark};
  }
`;

export interface ProductMenuLinkProps {
  className?: string;
  description?: string;
  handleClose: () => void;
  icon?: IconSource;
  link: string;
  title: string;
}

const ProductMenuLink: ForwardRefRenderFunction<
  HTMLAnchorElement,
  ProductMenuLinkProps
> = ({ className, icon, link, handleClose, title, description }, ref) => {
  const handleClick = useCallback(() => {
    // Delaying the link click so navigation (if applicable) can occur first
    const NAV_CLOSE_DELAY = 100;
    setTimeout(handleClose, NAV_CLOSE_DELAY);
  }, [handleClose]);

  return (
    <Anchor
      className={className}
      color="inherit"
      css={linkStyles}
      href={link}
      onClick={handleClick}
      ref={ref}
      role="listitem"
    >
      <Flex gap="1rem">
        {icon && (
          <FlexItem flex="0 0 32px">
            <Icon color="transparent" size="32px" source={icon} yPos="6px" />
          </FlexItem>
        )}
        <Flex flexDirection="column">
          <Flex alignItems="center" gap="1rem">
            <Text as="span" color="inherit" size="small" weight="semibold">
              {title}
            </Text>
          </Flex>
          {description && (
            <Text as="p" color="steelDarker" size="xsmall" weight="regular">
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    </Anchor>
  );
};

export default forwardRef(ProductMenuLink);
