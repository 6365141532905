import { css } from "@emotion/react";
import Text from "../../Text";
import AdditionalLink from "./AdditionalLink";
import Icon from "../../Icon";
import { MenuAdditionalLink, Mode } from "../types";

interface AdditionalLinksProps {
  links: MenuAdditionalLink[];
  mode: Mode;
  menuRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  menuLength: number;
}

const iconStyles = css`
  margin-right: 6px;
`;

const listStyles = css`
  display: flex;
  align-items: center;
  gap: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const AdditionalLinks = ({
  links,
  mode,
  menuRefs,
  menuLength,
}: AdditionalLinksProps) => {
  return (
    <ul css={listStyles}>
      {links.map((link, index) => (
        <AdditionalLink
          key={link.label}
          locale={link.locale}
          mode={mode}
          ref={(el) => el && (menuRefs.current[menuLength + index] = el)}
          target={link.target}
          url={link.url}
        >
          {link.hasIcon && (
            <Icon
              color="inherit"
              css={iconStyles}
              size="12px"
              source="search"
              yPos="1px"
            />
          )}
          <Text as="span" color="inherit" size="xsmall" weight="semibold">
            {link.label}
          </Text>
        </AdditionalLink>
      ))}
    </ul>
  );
};

export default AdditionalLinks;
