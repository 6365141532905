import { sizeXlarge } from "@10xdev/design-tokens";
import Link from "next/link";
import type { FunctionComponent } from "react";

import LogoDark from "../../assets/branding/logo-dark.svg";
import LogoLight from "../../assets/branding/logo-light.svg";

interface Props {
  /** The height of the component. Width adjusts proportionally. */
  height?: number | string;

  /** A URL that the browser should navigate to when the component is clicked or tapped. */
  href?: string;

  /** Specifies "light" (blue text) or "dark" (white text). */
  mode?: "light" | "dark";
}

/**
 * The 10x Genomics logo, in light or dark flavors.
 */
const Branding: FunctionComponent<Props> = ({
  height = sizeXlarge,
  href = "/",
  mode = "dark",
}) => {
  const Logo = mode === "dark" ? LogoLight : LogoDark;

  return (
    <Link href={href} legacyBehavior passHref={true}>
      <a title={"10x Genomics"}>
        <Logo height={height} />
      </a>
    </Link>
  );
};

export default Branding;
