import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Text from "../../Text";
import { Link } from "../types";
import Flex from "../../Flex";
import FeatureAnnouncementBanner, {
  FeatureAnnouncementBannerProps,
} from "../FeatureAnnouncementBanner";

interface Props {
  links: Link[];
  onNavigate: () => void;
  title?: string;
  featureAnnouncement?: FeatureAnnouncementBannerProps;
}

const NavLinksSection: FunctionComponent<Props> = ({
  links,
  onNavigate,
  title,
  featureAnnouncement,
}) => (
  <Flex flexDirection="column" gap="20px">
    {title ? (
      <Text as="span" color="steelDarkest" size="medium" weight="semibold">
        {title}
      </Text>
    ) : null}
    {links.map((link) => {
      return (
        <Flex flexDirection="column" key={link.id} padding="0.75rem 0">
          <Anchor
            color="inherit"
            href={link.link ?? ""}
            onClick={onNavigate}
            target="_self"
            underlineOnHover
          >
            <Text as="span" color="blueMedium" size="medium" weight="semibold">
              {link.title}
            </Text>
          </Anchor>
          {link.description && (
            <Text as="span" color="steelDarker" size="small" weight="regular">
              {link.description}
            </Text>
          )}
        </Flex>
      );
    })}
    {featureAnnouncement ? (
      <FeatureAnnouncementBanner {...featureAnnouncement} />
    ) : null}
  </Flex>
);

export default NavLinksSection;
