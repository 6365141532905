import { NavigationType } from "../types";

export const createCNNavigationData = (basePath: string): NavigationType => ({
  name: "Site nav",
  menu: [
    {
      type: "productMenu",
      label: "产品",
      items: [
        {
          id: "chromium-single-cell",
          title: "Chromium单细胞分析",
          description: "在新鲜、冷冻和FFPE组织中提供基因表达分析及多组学选择",
          link: `${basePath}/platforms/chromium`,
          panel: {
            type: "links",
            arrowPosition: "48px",
            rows: 3,
            links: [
              {
                id: "chromium-portfolio-overview",
                link: `${basePath}/platforms/chromium`,
                title: "Portfolio Overview",
                description: "Start your Chromium Single Cell journey here",
              },
              {
                id: "chromium-technology",
                link: `${basePath}/platforms/chromium/technology`,
                title: "Technology",
                description:
                  "Discover the core of accurate, reliable single cell technology",
              },
              {
                id: "chromium-products",
                link: `${basePath}/platforms/chromium/product-family`,
                title: "Products",
                description:
                  "Find out which single cell product is right for your research needs",
              },
              {
                id: "chromium-applications",
                link: `${basePath}/platforms/chromium/applications`,
                title: "Applications",
                description:
                  "Tackle a wide range of questions with additional capabilities & multiomic readouts",
              },
              {
                id: "chromium-x-series-instruments",
                link: `${basePath}/instruments/chromium-family`,
                title: "Chromium X Series Instruments",
                description:
                  "Explore capabilities of our instruments and the benefits of automated partitioning",
              },
              {
                id: "chromium-support-documentation",
                link: `${basePath}/support`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/Chromium_background.png",
              height: 126,
              width: 671,
            },
          },
        },
        {
          id: "visium-spatial",
          title: "Visium空间分析",
          description: "对组织样本进行无偏的空间发现",
          link: `${basePath}/platforms/visium`,
          panel: {
            type: "links",
            arrowPosition: "148px",
            rows: 2,
            links: [
              {
                id: "visium-portfolio-overview",
                link: `${basePath}/platforms/visium`,
                title: "Portfolio Overview",
                description: "Start your Visium Spatial journey here",
              },
              {
                id: "visium-products",
                link: `${basePath}/platforms/visium#assays`,
                title: "Products",
                description:
                  "Find out which Visium product is right for your research needs",
              },
              {
                id: "visium-instruments",
                link: `${basePath}/instruments/visium-cytassist`,
                title: "Instrument",
                description:
                  "See how Visium CytAssist can simplify your workflow & expand your sample access",
              },
              {
                id: "visium-support-documentation",
                link: `${basePath}/support`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Visium_background.png",
              height: 240,
              width: 671,
            },
          },
        },
        {
          id: "xenium-in-situ",
          title: "Xenium原位分析",
          description: "靶向分析组织样本中单个细胞内的多达5,000个基因",
          link: `${basePath}/platforms/xenium`,
          panel: {
            type: "links",
            arrowPosition: "246px",
            rows: 2,
            links: [
              {
                id: "xenium-portfolio-overview",
                link: `${basePath}/platforms/xenium`,
                title: "Portfolio Overview",
                description: "Start your Xenium In Situ journey here",
              },
              {
                id: "xenium-gene-expression-panels",
                link: `${basePath}/products/xenium-panels`,
                title: "Xenium Gene Expression Panels",
                description:
                  "Find out which Xenium panel is right for your research needs",
              },
              {
                id: "xenium-instruments",
                link: `${basePath}/instruments/xenium-analyzer`,
                title: "Instrument",
                description:
                  "See how Xenium Analyzer integrates high-resolution imaging & onboard data analysis",
              },
              {
                id: "xenium-support-documentation",
                link: `${basePath}/support/in-situ-gene-expression`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Xenium_background.png",
              height: 240,
              width: 671,
            },
          },
        },
        {
          id: "compare-products",
          panel: {
            type: "info",
            arrowPosition: "405px",
            bulletedList: [
              "兼容的物种和样本",
              "多组学分析能力",
              "通量",
              "分辨率",
              "混样选择",
              "兼容仪器",
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/compare_products_background.png",
              height: 240,
              width: 671,
            },
            title: "10x Genomics全部应用比较",
          },
          link: `${basePath}/products`,
          stickAtBottom: true,
          title: "产品比较",
        },
      ],
    },
    {
      type: "resourceMenu",
      label: "资源",
      items: [
        {
          description: "探索由10x Genomics产品提供支持的同行评审出版物",
          icon: "publications",
          id: "publications",
          link: `${basePath}/publications`,
          title: "文献",
        },
        {
          description: "访问使用10x Genomics产品创建的免费、多样化的数据集",
          icon: "datasets",
          id: "datasets",
          link: `${basePath}/datasets`,
          title: "数据集",
        },
        {
          description: "访问所有产品的用户指南、教程和实验方案",
          icon: "documentation",
          id: "support-documentation",
          link: `${basePath}/support`,
          title: "支持文档",
        },
        {
          description: "阅读超越10x软件的分析介绍和教程",
          icon: "analysis-guides-computer",
          id: "analysis-guides",
          link: `${basePath}/analysis-guides`,
          title: "分析指南",
        },
        {
          id: "blog",
          link: `${basePath}/blog`,
          title: "博客",
        },
        {
          id: "videos",
          link: `${basePath}/videos`,
          title: "视频",
        },
        {
          id: "library",
          link: `${basePath}/library`,
          title: "文档",
        },
        {
          id: "software",
          link: `${basePath}/software`,
          title: "软件",
        },
        {
          id: "service-providers",
          link: `${basePath}/service-providers`,
          title: "服务商",
        },
        {
          id: "events",
          link: `${basePath}/events`,
          title: "活动",
        },
      ],
    },
    {
      type: "link",
      label: "支持",
      url: `${basePath}/support`,
    },
    {
      type: "companyMenu",
      label: "公司",
      items: [
        {
          id: "about",
          link: `${basePath}/company`,
          title: "关于10x Genomics",
        },
        {
          id: "team-and-board",
          link: `${basePath}/company#executive-team`,
          title: "团队和董事会",
        },
        {
          id: "careers",
          link: `https://careers.10xgenomics.com/`,
          title: "职业发展",
        },
        {
          id: "investors",
          link: `https://investors.10xgenomics.com/`,
          title: "投资者",
        },
        {
          id: "news",
          link: `${basePath}/news`,
          title: "新闻",
        },
        {
          id: "distributors",
          link: `${basePath}/distributors`,
          title: "经销商",
        },
        {
          id: "contact-us",
          link: `${basePath}/contact`,
          title: "联系我们",
        },
      ],
    },
  ],
  additionalLinks: [
    {
      label: "Store",
      url: `${basePath}/store`,
      target: "_self",
      locale: false,
    },
    {
      label: "Search",
      url: `${basePath}/search`,
      target: "_self",
      hasIcon: true,
    },
  ],
});
