import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

interface MenuArrowProps {
  topPosition: string | number;
}

const arrowStyles = (topPosition: string | number) => css`
  border-right: 21px solid ${colorSteelLighter};
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  position: absolute;
  left: -20px;
  top: ${topPosition};
  transition: top 0.2s ease;

  &:after {
    border-right: 20px solid ${colorWhite};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    content: "";
    position: absolute;
    top: -20px;
    left: 1px;
  }
`;

const MenuArrow = ({ topPosition }: MenuArrowProps) => {
  return <div css={arrowStyles(topPosition)} />;
};

export default MenuArrow;
