import Grid from "../../Grid";
import Icon from "../../Icon";
import Image from "next/image";
import ProductMenuLink from "./ProductMenuLink";
import Text from "../../Text";
import { FeatureAnnouncement, InfoPanel, LinksPanel } from "../types";
import { colorSteelLighter } from "@10xdev/design-tokens";
import { css, keyframes } from "@emotion/react";
import { isInfoPanel, isLinksPanel } from "../utils";
import Flex from "../../Flex";
import FeatureAnnouncementBanner from "../FeatureAnnouncementBanner";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const panelContainerStyles = css`
  background-color: #ffffff;
  border-left: 1px solid ${colorSteelLighter};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 2.5rem;
  position: relative;
  width: 100%;

  animation: ${fadeIn} 0.1s ease-in-out;
`;

const imageContainerStyles = (height: number, width: number) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  height: ${height}px;
  width: ${width}px;
`;

interface ProductMenuPanelProps {
  className?: string;
  linkRefs?: React.MutableRefObject<Record<string, HTMLAnchorElement[]>>;
  panel: (InfoPanel | LinksPanel) & {
    featureAnnouncement?: FeatureAnnouncement;
  };
  menuId: string;
  handleClose: () => void;
}

const ProductMenuPanel = ({
  className,
  linkRefs,
  panel,
  menuId,
  handleClose,
}: ProductMenuPanelProps) => {
  const renderLinksPanel = isLinksPanel(panel) ? (
    <Grid
      gap="2rem"
      gridAutoFlow="column"
      gridTemplateRows={`repeat(${panel.rows}, 64px)`}
    >
      {panel.links.map(({ link, title, id, description }, index) => (
        <ProductMenuLink
          key={id}
          description={description}
          link={link}
          title={title}
          handleClose={handleClose}
          ref={(el) => {
            if (!el || !linkRefs) {
              return;
            }
            linkRefs.current[menuId] = linkRefs.current[menuId] || [];
            linkRefs.current[menuId][index] = el;
          }}
        />
      ))}
    </Grid>
  ) : null;

  const renderInfoPanel = isInfoPanel(panel) ? (
    <div>
      <Text as="h2" color="steelDarker" size="small" weight="medium">
        {panel.title}
      </Text>
      <Grid
        css={css`
          margin-top: 1.5rem;
        `}
        gap="1rem 2rem"
        gridAutoFlow="column"
        gridTemplateRows="repeat(3, auto)"
      >
        {panel.bulletedList.map((item) => (
          <Flex key={item} gap="1rem" role="listitem">
            <Icon color="blue" size="20px" source="blue-checkmark" yPos="5px" />
            <Text as="span" color="steelDarker" size="small" weight="regular">
              {item}
            </Text>
          </Flex>
        ))}
      </Grid>
    </div>
  ) : null;

  return (
    <div className={className} css={panelContainerStyles}>
      {renderLinksPanel}
      {renderInfoPanel}
      <div css={imageContainerStyles(panel.image.height, panel.image.width)}>
        <Image alt="" src={panel.image} />
      </div>
      {panel.featureAnnouncement && (
        <div
          css={css`
            position: absolute;
            bottom: 0;
            right: 0;
          `}
        >
          <FeatureAnnouncementBanner
            title={panel.featureAnnouncement.title}
            description={panel.featureAnnouncement.description}
            link={panel.featureAnnouncement.link}
            tag={panel.featureAnnouncement.tag}
            image={panel.featureAnnouncement.image}
          />
        </div>
      )}
    </div>
  );
};

export default ProductMenuPanel;
