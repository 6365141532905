import { useEffect, useState } from "react";

export interface MenuKeydownHandlers {
  onEscapeKeyDown?: () => void;
  onShiftTabKeyDown?: () => void;
  onTabKeyDown?: () => void;
}

export interface MenuKeydownHandlerRefs {
  submenuLinks: HTMLAnchorElement[];
  submenus: HTMLAnchorElement[];
}

/**
 * Handles keyboard navigation for a menu.
 * - Tab: Move focus to the next menu.
 * - Shift + Tab: Move focus to the previous menu.
 * - ArrowDown: Move focus to the next sub menu item.
 * - ArrowUp: Move focus to the previous sub menu item.
 * - ArrowRight: Move focus to the next link on the right panel. (currently only applies to product menu)
 * - ArrowLeft: Move focus to the previous link on the right panel. (currently only applies to product menu)
 * - Escape: Close the menu.
 */
export const useMenuKeydownHandlers = ({
  onEscapeKeyDown,
  onShiftTabKeyDown,
  onTabKeyDown,
  submenus,
  submenuLinks,
}: MenuKeydownHandlers & MenuKeydownHandlerRefs) => {
  const [activeLinkIndex, setActiveLinkIndex] = useState(-1);

  useEffect(() => {
    if (activeLinkIndex !== -1) {
      submenuLinks[activeLinkIndex]?.focus();
    }
  }, [activeLinkIndex, submenuLinks]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    index: number,
  ) => {
    const moveFocus = (direction: number, items: HTMLAnchorElement[]) => {
      const newIndex = (index + direction + items.length) % items.length;
      items[newIndex]?.focus();
      setActiveLinkIndex(-1);
    };

    const moveLinkFocus = (direction: number) => {
      const newLinkIndex =
        (activeLinkIndex + direction + submenuLinks.length) %
        submenuLinks.length;
      setActiveLinkIndex(newLinkIndex);
    };

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        moveFocus(1, submenus);
        break;
      case "ArrowUp":
        event.preventDefault();
        moveFocus(-1, submenus);
        break;
      case "Tab":
        event.preventDefault();
        if (event.shiftKey) {
          onShiftTabKeyDown?.();
        } else {
          onTabKeyDown?.();
        }
        break;
      case "Escape":
        event.preventDefault();
        onEscapeKeyDown?.();
        break;
      case "ArrowRight":
        event.preventDefault();
        moveLinkFocus(1);
        break;
      case "ArrowLeft":
        event.preventDefault();
        moveLinkFocus(-1);
        break;
    }
  };

  return { handleKeyDown };
};
