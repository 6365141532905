import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";

import Icon from "../../Icon";
import IconButton from "../../IconButton";
import MobileNavMenu from "./MobileNavMenu";
import { Mode, NavigationType } from "../types";

interface Props {
  data: NavigationType;
  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: Mode;
}

const MobileNavigation: FunctionComponent<Props> = ({ data, mode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav
      css={css`
        display: none;
        margin-left: auto;

        @media (max-width: 1070px) {
          display: initial;
        }
      `}
    >
      <IconButton onClick={toggleMenu}>
        <Icon
          color={mode === "dark" ? "white" : "base"}
          size="28px"
          source="hamburger"
          title="Open Menu"
          yPos="4px"
        />
      </IconButton>

      {isOpen && (
        <MobileNavMenu
          data={data}
          onClose={toggleMenu}
          onNavigate={toggleMenu}
        />
      )}
    </nav>
  );
};

export default MobileNavigation;
