import { colorSteelLighter, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef, type ForwardRefRenderFunction } from "react";

import type { IconSource } from "../../Icon/types";
import MenuListItem from "./MenuListItem";
import { ResourceMenuItemType } from "../types";
import { MenuKeydownHandlers } from "./useMenuKeydownHandlers";
import Flex from "../../Flex";
import FlexItem from "../../Flex/FlexItem";
import { useMenuNavigation } from "./useMenuNavigation";

const menuContainerStyles = css`
  background-color: ${colorWhite};
  border-radius: 16px;
  width: 640px;
  border: 1px solid ${colorSteelLighter};
  overflow: hidden;
  padding: 1rem 0;
`;

interface ResourceMenuProps extends MenuKeydownHandlers {
  handleClose: () => void;
  menuItems: ResourceMenuItemType[];
}

const ResourceMenu: ForwardRefRenderFunction<
  HTMLDivElement,
  ResourceMenuProps
> = (
  { handleClose, menuItems, onEscapeKeyDown, onShiftTabKeyDown, onTabKeyDown },
  ref,
) => {
  const {
    submenuRefs,
    activeMenuItemId,
    activeMenuItemIndex,
    handleKeyDown,
    handleMenuItemHover,
    handleMenuItemLeave,
  } = useMenuNavigation({
    menuItems,
    onEscapeKeyDown,
    onShiftTabKeyDown,
    onTabKeyDown,
  });
  return (
    <div
      css={menuContainerStyles}
      onKeyDown={(event) => handleKeyDown(event, activeMenuItemIndex)}
      ref={ref}
    >
      <div
        css={css`
          height: 100%;
        `}
      >
        <Flex>
          {["withIcons", "withoutIcons"].map((group) => (
            <FlexItem flex={group == "withIcons" ? "1.2" : "1"} key={group}>
              <Flex
                flexDirection="column"
                justifyContent="flex-start"
                role="list"
              >
                {menuItems
                  .filter((item) =>
                    group === "withIcons" ? !!item.icon : !item.icon,
                  )
                  .map((item, index) => {
                    const currentIndex =
                      group === "withIcons"
                        ? index
                        : menuItems.filter((i) => !!i.icon).length + index;

                    return (
                      <MenuListItem
                        key={item.id}
                        active={item.id === activeMenuItemId}
                        compact={true}
                        description={item.description}
                        handleClose={handleClose}
                        icon={item.icon as IconSource}
                        link={item.link}
                        onHover={() => handleMenuItemHover(item.id)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, currentIndex)
                        }
                        onMouseLeave={() => {
                          handleMenuItemLeave();
                          submenuRefs.current[currentIndex]?.blur();
                        }}
                        ref={(el) =>
                          el && (submenuRefs.current[currentIndex] = el)
                        }
                        title={item.title}
                      />
                    );
                  })}
              </Flex>
            </FlexItem>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default forwardRef(ResourceMenu);
