import { NavigationType } from "../types";

export const createENNavigationData = (basePath: string): NavigationType => ({
  name: "Site nav",
  menu: [
    {
      type: "productMenu",
      label: "Products",
      items: [
        {
          id: "chromium-single-cell",
          title: "Chromium Single Cell",
          description:
            "Profile gene expression, with multiomic options in fresh, frozen & FFPE tissue",
          link: `${basePath}/platforms/chromium`,
          panel: {
            type: "links",
            arrowPosition: "48px",
            rows: 3,
            links: [
              {
                id: "chromium-portfolio-overview",
                link: `${basePath}/platforms/chromium`,
                title: "Portfolio Overview",
                description: "Start your Chromium Single Cell journey here",
              },
              {
                id: "chromium-technology",
                link: `${basePath}/platforms/chromium/technology`,
                title: "Technology",
                description:
                  "Discover the core of accurate, reliable single cell technology",
              },
              {
                id: "chromium-products",
                link: `${basePath}/platforms/chromium/product-family`,
                title: "Products",
                description:
                  "Find out which single cell product is right for your research needs",
              },
              {
                id: "chromium-applications",
                link: `${basePath}/platforms/chromium/applications`,
                title: "Applications",
                description:
                  "Tackle a wide range of questions with additional capabilities & multiomic readouts",
              },
              {
                id: "chromium-x-series-instruments",
                link: `${basePath}/instruments/chromium-family`,
                title: "Chromium X Series Instruments",
                description:
                  "Explore capabilities of our instruments and the benefits of automated partitioning",
              },
              {
                id: "chromium-support-documentation",
                link: `${basePath}/support`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/Chromium_background.png",
              height: 126,
              width: 671,
            },
          },
        },
        {
          id: "visium-spatial",
          title: "Visium Spatial",
          description:
            "Perform whole transcriptome discovery in the tissue context",
          link: `${basePath}/platforms/visium`,
          panel: {
            type: "links",
            arrowPosition: "148px",
            rows: 2,
            links: [
              {
                id: "visium-portfolio-overview",
                link: `${basePath}/platforms/visium`,
                title: "Portfolio Overview",
                description: "Start your Visium Spatial journey here",
              },
              {
                id: "visium-products",
                link: `${basePath}/platforms/visium#assays`,
                title: "Products",
                description:
                  "Find out which Visium product is right for your research needs",
              },
              {
                id: "visium-instruments",
                link: `${basePath}/instruments/visium-cytassist`,
                title: "Instrument",
                description:
                  "See how Visium CytAssist can simplify your workflow & expand your sample access",
              },
              {
                id: "visium-support-documentation",
                link: `${basePath}/support`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Visium_background.png",
              height: 240,
              width: 671,
            },
          },
        },
        {
          id: "xenium-in-situ",
          title: "Xenium In Situ",
          description:
            "Profile up to 5000 RNA targets in tissue sections with single cell spatial imaging",
          link: `${basePath}/platforms/xenium`,
          panel: {
            type: "links",
            arrowPosition: "246px",
            rows: 2,
            links: [
              {
                id: "xenium-portfolio-overview",
                link: `${basePath}/platforms/xenium`,
                title: "Portfolio Overview",
                description: "Start your Xenium In Situ journey here",
              },
              {
                id: "xenium-gene-expression-panels",
                link: `${basePath}/products/xenium-panels`,
                title: "Xenium Gene Expression Panels",
                description:
                  "Find out which Xenium panel is right for your research needs",
              },
              {
                id: "xenium-instruments",
                link: `${basePath}/instruments/xenium-analyzer`,
                title: "Instrument",
                description:
                  "See how Xenium Analyzer integrates high-resolution imaging & onboard data analysis",
              },
              {
                id: "xenium-support-documentation",
                link: `${basePath}/support/in-situ-gene-expression`,
                title: "Support Hub",
                description:
                  "Access comprehensive resources, including user guides, protocols, tutorials & more",
              },
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391196/navigation/Xenium_background.png",
              height: 200,
              width: 671,
            },
          },
        },
        {
          id: "compare-products",
          panel: {
            type: "info",
            arrowPosition: "405px",
            bulletedList: [
              "Compatible species and samples",
              "Multiomic capabilities",
              "Throughput",
              "Resolution",
              "Multiplexing options",
              "Compatible instruments",
            ],
            image: {
              src: "https://cdn.10xgenomics.com/image/upload/dpr_2.0,f_auto,q_auto/v1727391195/navigation/compare_products_background.png",
              height: 240,
              width: 671,
            },
            title: "Compare all 10x Genomics assays by",
          },
          link: `${basePath}/products`,
          stickAtBottom: true,
          title: "Compare Products",
        },
      ],
    },
    {
      type: "resourceMenu",
      label: "Resources",
      items: [
        {
          description:
            "Explore peer-reviewed publications powered by 10x Genomics products",
          icon: "publications",
          id: "publications",
          link: `${basePath}/publications`,
          title: "Publications",
        },
        {
          description:
            "Access free, diverse datasets created with 10x Genomics products",
          icon: "datasets",
          id: "datasets",
          link: `${basePath}/datasets`,
          title: "Datasets",
        },
        {
          description:
            "Access user guides, tutorials, and protocols for all products",
          icon: "documentation",
          id: "support-documentation",
          link: `${basePath}/support`,
          title: "Support Hub",
        },
        {
          description:
            "Read analysis introductions and tutorials beyond 10x software",
          icon: "analysis-guides-computer",
          id: "analysis-guides",
          link: `${basePath}/analysis-guides`,
          title: "Analysis Guides",
        },
        {
          id: "blog",
          link: `${basePath}/blog`,
          title: "Blog",
        },
        {
          id: "videos",
          link: `${basePath}/videos`,
          title: "Videos",
        },
        {
          id: "library",
          link: `${basePath}/library`,
          title: "Library",
        },
        {
          id: "software",
          link: `${basePath}/software`,
          title: "Software",
        },
        {
          id: "service-providers",
          link: `${basePath}/service-providers`,
          title: "Service Providers",
        },
        {
          id: "events",
          link: `${basePath}/events`,
          title: "Events",
        },
      ],
    },
    {
      type: "link",
      label: "Support Hub",
      url: `${basePath}/support`,
    },
    {
      type: "companyMenu",
      label: "Company",
      items: [
        {
          id: "about",
          link: `${basePath}/company`,
          title: "About 10x Genomics",
        },
        {
          id: "team-and-board",
          link: `${basePath}/company#executive-team`,
          title: "Team and Board",
        },
        {
          id: "careers",
          link: `https://careers.10xgenomics.com/`,
          title: "Careers",
        },
        {
          id: "investors",
          link: `https://investors.10xgenomics.com/`,
          title: "Investors",
        },
        {
          id: "news",
          link: `${basePath}/news`,
          title: "News",
        },
        {
          id: "distributors",
          link: `${basePath}/distributors`,
          title: "Distributors",
        },
        {
          id: "contact-us",
          link: `${basePath}/contact`,
          title: "Contact Us",
        },
      ],
    },
  ],
  additionalLinks: [
    {
      label: "Store",
      url: `${basePath}/store`,
      target: "_self",
      locale: false,
    },
    {
      label: "Search",
      url: `${basePath}/search`,
      target: "_self",
      hasIcon: true,
    },
  ],
});
