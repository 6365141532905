import {
  ProductMenuType,
  ResourceMenuType,
  CompanyMenuType,
  LinkMenuType,
  InfoPanel,
  LinksPanel,
  Panel,
  ProductMenuItemType,
  ResourceMenuItemType,
  CompanyMenuItemType,
  NavigationType,
  MenuAdditionalLink,
} from "./types";
import { createENNavigationData } from "./data/en";
import { createJPNavigationData } from "./data/jp";
import { createCNNavigationData } from "./data/cn";

export function getNavigationData(
  locale: string,
  basePath: string,
): NavigationType {
  switch (locale) {
    case "en":
      return createENNavigationData(basePath);
    case "jp":
      return createJPNavigationData(basePath);
    case "cn":
      return createCNNavigationData(basePath);
    default:
      return createENNavigationData(basePath);
  }
}

export function isProductMenu(menu: any): menu is ProductMenuType {
  return menu.type === "productMenu";
}

export function isResourceMenu(menu: any): menu is ResourceMenuType {
  return menu.type === "resourceMenu";
}

export function isCompanyMenu(menu: any): menu is CompanyMenuType {
  return menu.type === "companyMenu";
}

export function isLinkMenu(menu: any): menu is LinkMenuType {
  return menu.type === "link";
}

export function isMenuAdditionalLink(menu: any): menu is MenuAdditionalLink {
  return "url" in menu && "label" in menu && "target" in menu;
}

export function isProductMenuItem(item: any): item is ProductMenuItemType {
  return item && typeof item === "object" && "panel" in item;
}

export function isResourceMenuItem(item: any): item is ResourceMenuItemType {
  return item && typeof item === "object" && "icon" in item;
}

export function isCompanyMenuItem(item: any): item is CompanyMenuItemType {
  return (
    item && typeof item === "object" && !("panel" in item) && !("icon" in item)
  );
}

export function isLinksPanel(panel: Panel): panel is LinksPanel {
  return panel.type === "links";
}

export function isInfoPanel(panel: Panel): panel is InfoPanel {
  return panel.type === "info";
}
