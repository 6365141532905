import { useRef, useCallback, useMemo } from "react";
import Popup from "reactjs-popup";
import type { PopupActions } from "reactjs-popup/dist/types";
import ProductMenu from "./ProductMenu";
import ResourceMenu from "./ResourceMenu";
import CompanyMenu from "./CompanyMenu";
import Button from "../../Button";
import { Menu } from "../types";
import Text, { colors } from "../../Text";
import { isCompanyMenu, isProductMenu, isResourceMenu } from "../utils";

interface NavigationPopupProps {
  navItem: Menu;
  index: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  textColor: keyof typeof colors;
  getNavItemStyle: (isActive: boolean) => any;
  menuRefs: React.MutableRefObject<(HTMLElement | null)[]>;
}

const NavigationPopup = ({
  navItem,
  index,
  activeIndex,
  setActiveIndex,
  textColor,
  getNavItemStyle,
  menuRefs,
}: NavigationPopupProps) => {
  const popupRef = useRef<PopupActions | null>(null);

  const closePopup = useCallback(() => {
    popupRef.current?.close();
    menuRefs.current[index]?.focus();
  }, [index, menuRefs]);

  const commonProps = useMemo(
    () => ({
      handleClose: closePopup,
      onEscapeKeyDown: closePopup,
      onShiftTabKeyDown: () => {
        closePopup();
        menuRefs.current[Math.max(0, index - 1)]?.focus();
      },
      onTabKeyDown: () => {
        closePopup();
        menuRefs.current[
          Math.min(menuRefs.current.length - 1, index + 1)
        ]?.focus();
      },
    }),
    [closePopup, index, menuRefs],
  );

  const renderMenu = useMemo(() => {
    if (isProductMenu(navItem)) {
      return <ProductMenu menuItems={navItem.items} {...commonProps} />;
    }
    if (isResourceMenu(navItem)) {
      return <ResourceMenu menuItems={navItem.items} {...commonProps} />;
    }
    if (isCompanyMenu(navItem)) {
      return <CompanyMenu menuItems={navItem.items} {...commonProps} />;
    }
    return null;
  }, [navItem, commonProps]);

  const getOffsetX = useMemo(() => {
    switch (true) {
      case isCompanyMenu(navItem):
        return -48;
      case isProductMenu(navItem):
        return -195;
      default:
        return -120;
    }
  }, [navItem]);

  return (
    <Popup
      arrow={false}
      closeOnDocumentClick
      key={navItem.label}
      offsetX={getOffsetX}
      offsetY={16}
      on={["hover", "click"]}
      onClose={() => setActiveIndex(-1)}
      onOpen={() => setActiveIndex(index)}
      position="bottom left"
      ref={popupRef}
      trigger={
        <li key={navItem.label}>
          <Button
            aria-expanded={activeIndex === index}
            aria-haspopup="true"
            background="transparent"
            css={getNavItemStyle(activeIndex === index)}
            onFocus={() => popupRef.current?.open()}
            ref={(el) => (menuRefs.current[index] = el)}
          >
            <Text as="span" color={textColor} size="small" weight="semibold">
              {navItem.label}
            </Text>
          </Button>
        </li>
      }
    >
      {renderMenu}
    </Popup>
  );
};

export default NavigationPopup;
