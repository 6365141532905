import { useMemo } from "react";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { Mode } from "./types";
import { getNavigationData } from "./utils";

interface Props {
  basePath?: string;
  locale?: string;
  /** Specifies white text (dark mode) or blue text (light mode). */
  mode?: Mode;
}

const Navigation = ({ basePath = "", locale = "en", mode = "dark" }: Props) => {
  const navigationData = useMemo(
    () => getNavigationData(locale, basePath),
    [basePath, locale],
  );

  return (
    <>
      <DesktopNavigation data={navigationData} mode={mode} />
      <MobileNavigation data={navigationData} mode={mode} />
    </>
  );
};

export default Navigation;
