import { colorSteelLighter, sizeLarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { CSSProperties, FunctionComponent } from "react";

interface Props {
  className?: string;
  style?: CSSProperties;
}

/**
 * A full-width, horizontal divider that represents a
 * thematic break between paragraph-level elements.
 */
const ThematicBreak: FunctionComponent<Props> = ({ className, style }) => (
  <hr
    className={className}
    css={css`
      background-color: ${colorSteelLighter};
      border: 0;
      box-sizing: border-box;
      height: 1px;
      margin: ${sizeLarge} auto;
      width: 100%;
    `}
    style={style}
  />
);

export default ThematicBreak;
