import { css } from "@emotion/react";
import Anchor from "../../Anchor";
import Flex from "../../Flex";
import Icon from "../../Icon";
import Text from "../../Text";
import Image from "next/image";
import { mediaTabletLandscape } from "@10xdev/design-tokens";

export interface FeatureAnnouncementBannerProps {
  title: string;
  description: string;
  link: {
    href: string;
    text: string;
  };
  tag: string;
  image?: {
    src: string;
    width?: number;
    height?: number;
  };
}

const Tag = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => (
  <Text
    as="span"
    className={className}
    size="xxxsmall"
    weight="semibold"
    css={css`
      background: #e9ecff;
      color: #735faf;
      padding: 0.25rem 0.5rem;
      border-radius: 6px;
      letter-spacing: 0.3px;
      line-height: 130%;
    `}
  >
    {children.toUpperCase()}
  </Text>
);

const FeatureAnnouncementBanner = ({
  description,
  image,
  link,
  tag,
  title,
}: FeatureAnnouncementBannerProps) => {
  return (
    <Flex
      alignItems={{ base: "flex-start", tabletLandscape: "center" }}
      css={css`
        background: rgba(250, 250, 250, 0.94);
        background: #fafafaf0;
      `}
      flexDirection={{ base: "column", tabletLandscape: "row" }}
      gap={{ base: "1rem", tabletLandscape: "2rem" }}
      padding={{ base: "1rem 1.5rem", tabletLandscape: "1rem 2.5rem" }}
    >
      {image?.src && (
        <Image
          src={image.src}
          alt={""}
          width={image.width ?? 120}
          height={image.height ?? 120}
        />
      )}
      <Flex flexDirection="column" gap="0.5rem">
        <Flex
          alignItems={{ base: "flex-start", tabletLandscape: "center" }}
          flexDirection={{ base: "column", tabletLandscape: "row" }}
          gap="0.5rem"
        >
          <Text
            as="h4"
            css={css`
              order: 2;
              @media (min-width: ${mediaTabletLandscape}) {
                order: 1;
              }
            `}
            size="small"
            weight="semibold"
          >
            {title}
          </Text>
          <Tag
            css={css`
              order: 1;
              @media (min-width: ${mediaTabletLandscape}) {
                order: 2;
              }
            `}
          >
            {tag}
          </Tag>
        </Flex>
        <Text as="p" size="small" weight="regular">
          {description}
        </Text>
        <Anchor href={link.href}>
          <Flex alignItems="center" gap="1rem">
            <Text as="span" color="inherit" size="small" weight="semibold">
              {link.text}
            </Text>
            <Icon size="18px" source="arrow-right" />
          </Flex>
        </Anchor>
      </Flex>
    </Flex>
  );
};

export default FeatureAnnouncementBanner;
